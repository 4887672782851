/* eslint-disable camelcase */
// Supporting selecting & replacing Flexpro variations
// for posts' titles & thumbnails
export const FLEXPRO_KEY = 'metadata_variations';

function randomlySelectVariant(variationArray) {
  const idx = Math.floor(Math.random() * variationArray.length);
  return variationArray[idx];
}

function hasFlexproVariations(postData) {
  return FLEXPRO_KEY in postData;
}

/**
 * The function `pickFlexproVariation` was designed to handle responses from weaver. This data
 * normalizing function will take a `feed_api` response and convert the data to match the format of
 * the weaver API.
 * @see https://github.com/buzzfeed/mono/blob/0fc3b41/feed_ui/src/upstreams/weaver.js#L8-L32
 * @param {Object} postData - feed_api post data.
 * @returns {Object} - The normalized post data matching weaver_api responses.
 */
function normalizePostData(postData) {
  if (postData.experiments?.length) {
    const { variations = [] } = postData.experiments.find(({ status }) => status === 'active') || {};

    if (variations.length) {
      return {
        ...postData,
        [FLEXPRO_KEY]: variations,
        thumbnails: [
          {
            sizes: Object.entries(postData.images)
              .filter(([size]) => !size.includes('alt_text'))
              .map(([size, url]) => ({ size, url, alt_text: postData.images[`${size}_alt_text`] })),
          },
        ],
      };
    }
  }

  return { ...postData };
}

/**
 * Denormalize the post data by converting data back from weaver formated data to the original
 * feed_api format.
 * @param {Object} postData - The normalized post data.
 * @returns {Object} - The denormalized post data.
 */
function denormalizePostData(postData) {
  if (postData.experiments?.length && postData.thumbnails?.length) {
    const images = postData.thumbnails[0].sizes.reduce((imagesAcc, { size, url, alt_text }) => {
      imagesAcc[size] = url;
      imagesAcc[`${size}_alt_text`] = alt_text;
      return imagesAcc;
    }, {});

    return {
      ...postData,
      images: {
        ...postData.images,
        ...images,
      },
    };
  }

  return { ...postData };
}

export const pickFlexproVariation = postData => {
  const normalizedPostData = normalizePostData(postData);

  if (!hasFlexproVariations(normalizedPostData)) {
    return postData;
  }

  const variation = randomlySelectVariant(normalizedPostData[FLEXPRO_KEY]);
  let variantPostData = { ...normalizedPostData }; // dupe postData

  // overwrite variant data into the new post data
  if (variation.title) {
    variantPostData.title = variation.title;
  }

  if (variation.id) {
    variantPostData.variation_id = variation.id;
    variantPostData.flexpro_source_algorithm = 'flexpro_v2';
    variantPostData.flexpro_source_algorithm_version = `variation:${variation.id}`;
  }

  if (variation.thumbnail) {
    const origThumbs = normalizedPostData.thumbnails[0].sizes;
    const modifiedThumbs = origThumbs.map(sizeData => {
      let { size } = sizeData;
      if (size === 'dblbig' || size === 'dblwide') {
        const imgUrlKey = (size === 'dblwide' ? 'wide_' : '') + 'url';
        return {
          size,
          alt_text: variation.thumbnail.alt_text || '',
          url: variation.thumbnail[imgUrlKey],
        };
      } else if (size === 'big') {
        // just set to url (dblbig) size for now since it's just a test image
        return {
          size,
          alt_text: variation.thumbnail.alt_text || '',
          url: variation.thumbnail['url'] + '&resize=300:*',
        };
      } else if (['wide', 'small', 'square', 'standard', 'wide_16x9'].includes(size)) {
        return {
          size,
          alt_text: variation.thumbnail.alt_text || '',
          url: variation.thumbnail[`${size}_url`],
        };
      } else {
        return sizeData; // leave other sizes untouched
      }
    });
    // shape like the rest of the data for data normalization after weaver call
    variantPostData.thumbnails = [{ sizes: modifiedThumbs }];
  }
  variantPostData.is_flexpro = true;
  variantPostData[FLEXPRO_KEY] = null;
  return denormalizePostData(variantPostData);
};
