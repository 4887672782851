import { useIntersection } from '@buzzfeed/react-components';

/**
 * @typedef {Object} useLazyLoadResult
 * @property {Boolean} canLoad - whether the component can start delayed computations
 * @property {Function} setObservable - should be called with the component's root DOM node
 */

/**
 * Hook for delaying computations based on a component's position relative to viewport
 * @param {Number} [options.preloadDistance=2] - how close to viewport the component shoud be to be considered "ready";
 *  `{preloadDistance: N}` means distance equal or less than N times viewport height
 * @param {String} [options.rootMargin] - raw `rootMargin` param for an intersection observer
 *  (takes precedence over `preloadDistance`)
 * @returns {useLazyLoadResult}
 */
export const useLazyLoad = ({
  preloadDistance = 2,
  rootMargin,
  onlyOnce = true,
} = {}) => {
  if (rootMargin === undefined) {
    const pageHeight =
      typeof document !== 'undefined'
        ? document.documentElement.clientHeight
        : 0;
    rootMargin = `${pageHeight * preloadDistance}px 0px`;
  }
  const { isIntersecting, setObservable } = useIntersection({
    rootMargin,
    once: onlyOnce,
  });

  return { canLoad: isIntersecting, setObservable };
};

export default useLazyLoad;
