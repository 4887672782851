/* eslint-disable camelcase */
import { useTrackingContext } from '../../hooks';

export const LinkWithTracking = ({
  href,
  children,
  className = '',
  commonTrackingData = {},
}) => {
  const { trackExternalLink, trackInternalLink } = useTrackingContext();
  const isExternalLink = !href ? false : !href.toLowerCase().match(/.*buzzfeed\.(com|io)|(^\/.*$)/);
  let softlock = false; // mouseup triggers onclick, but want to use onclick fo mweb, too
  const _handleClickTracking = (ev) => {
    if(!softlock) {
      softlock = true;
      let click_type = 'left';
      if (ev.button === 1) {
        click_type = 'middle';
      } else if (ev.type === 'contextmenu') {
        click_type = 'right';
      }
      if (isExternalLink) {
        trackExternalLink({
          ...commonTrackingData,
          click_type,
          target_content_url: href,
          target_content_type: undefined,
          target_content_id: undefined,
        });
      } else {
        trackInternalLink({
          click_type,
          ...commonTrackingData
        });
      }
      setTimeout(() => { softlock = false; }, 50);
    }
  };
  return (
    <a
      href={href}
      onClick={_handleClickTracking}
      onContextMenu={_handleClickTracking} // get right/ctrl/cmd clicks
      onMouseUp={_handleClickTracking}
      className={className}>
     {children}
    </a>
  );
};

export default LinkWithTracking;
