import { formatDistanceToNowStrict, sub, isAfter } from 'date-fns';
import merge from 'lodash.merge';
import { addParams } from '@buzzfeed/bf-utils/lib/query-string';
import { imgServiceParams } from './image';
import { pickFlexproVariation } from './flexpro';
import {
  TASTY,
  SHOPPING,
  NEWS,
  QUIZ,
  TRENDING,
  MORE,
  SPLASH,
  VIDEO,
  SPONSORED,
} from '../constants/feeds';
import { bfUrl } from '../constants';

const imageOutputParams = {
  'output-format': 'auto',
  'output-quality': 'auto'
};

const generalResizeParams = {
  fill: '266:176;center,top',
  ...imageOutputParams
};

const topicSplashResizeParams = {
  fill: '720:480;center,top',
  ...imageOutputParams
};

const generalResizeParamsSquare = {
  fill: '480:480;center,top',
  ...imageOutputParams
};

export const formatNumberToShorthand = (num, dec = 1) => {
  if (!num) {
    return '0';
  }

  const abbreviations = { billion: 'B', million: 'M', thousand: 'K' };

  if (num >= 1e9) return (num / 1e9).toFixed(dec).replace(/\.0+$/, '') + abbreviations.billion;
  if (num >= 1e6) return (num / 1e6).toFixed(dec).replace(/\.0+$/, '') + abbreviations.million;
  if (num >= 1e3) return (num / 1e3).toFixed(dec).replace(/\.0+$/, '') + abbreviations.thousand;

  return num.toString();
}

// Make quotes and apostrophes "Curly"
const formatQuotes = (str) => {
  if (!str) {
    return '';
  }

  try {
    str = String(str);
  } catch (error) {
    return '';
  }

  return str
    .replace(/\b'/g, '’')
    .replace(/"\b/g, '\u201c')
    .replace(/\b"/g, '\u201d');
};

export const formatTimestamp = createdAt => {
  var createdTime = formatDistanceToNowStrict(new Date(createdAt), {
    addSuffix: true,
  });

  const formattedTime = createdTime
    .replace('hour', 'hr')
    .replace('hours', 'hr')
    .replace('minute', 'min')
    .replace('minutes', 'min')
    .replace('second', 'sec')
    .replace('seconds', 'sec');

  return formattedTime;
};

export const formatCategoryInfo = (label, badge, type, authorInfo) => {
  let formattedInfo = undefined;

  if (badge === 'quiz') {
    return { label: 'Quiz', url: `${bfUrl}/quizzes` };
  }

  if (type === VIDEO) {
    switch (authorInfo?.name) {
      case undefined:
        return { label: 'BuzzFeed Video', url: `${bfUrl}/videos` };
      case 'As/Is':
        return { label: 'As/Is', url: `${bfUrl}/asis/videos` };
      case 'BuzzFeed Unsolved: Supernatural':
        return { label: authorInfo?.name, url: `${bfUrl}/unsolved` };
      case 'BuzzFeed Unsolved: True Crime':
        return { label: authorInfo?.name, url: `${bfUrl}/unsolved` };
      default:
        return {
          label: authorInfo?.name,
          url: `${bfUrl}/${authorInfo?.username.replace(/_/g, '')}`,
        };
    }
  }

  switch (label) {
    case undefined:
    case 'BuzzFeed':
      formattedInfo = { label: 'Buzz', url: `${bfUrl}/tag/buzz` };
      break;
    case 'As/Is':
      formattedInfo = { label: 'Beauty', url: `${bfUrl}/asis` };
      break;
    case 'Goodful':
      formattedInfo = { label: 'Health', url: `${bfUrl}/goodful` };
      break;
    case 'Nifty':
      formattedInfo = { label: 'Home & Living', url: `${bfUrl}/nifty` };
      break;
    case 'Internet Finds':
      formattedInfo = {
        label: 'Internet Finds',
        url: `${bfUrl}/bestoftheinternet`,
      };
      break;
    case 'LGBT':
      formattedInfo = { label: 'LGBTQ', url: `${bfUrl}/lgbtq` };
      break;
    case 'TVAndMovies':
      formattedInfo = {
        label: 'TV & Movies',
        url: `${bfUrl}/tvandmovies`,
      };
      break;
    case 'National':
      formattedInfo = {
        label: 'National',
        url: 'https://www.buzzfeednews.com/section/national',
      };
      break;
    case 'Inequality':
      formattedInfo = {
        label: 'Inequality',
        url: 'https://www.buzzfeednews.com/section/inequality',
      };
      break;
    default:
      formattedInfo = {
        label: label,
        url: label
          ? `${bfUrl}/${label
              .toLowerCase()
              .replace(/&/g, 'and')
              .replace(/ /g, '-')}`
          : '/',
      };
  }

  return formattedInfo;
};

export const formatVideoDuration = durationMs => {
  // duration is in milliseconds
  const totalSeconds = parseInt(durationMs, 10) / 1000;
  const mins = totalSeconds / 60;
  const justMins = parseInt(`${mins}`.split('.')[0], 10);
  const decimalSeconds = (mins - justMins).toFixed(2);
  const secs = Math.ceil(60 * decimalSeconds);
  return `${justMins}:${secs < 10 ? '0' : ''}${secs}`;
};

export const remapThumbnailData = sizeArray => {
  const thumbMap = {};
  sizeArray.forEach(thumb => {
    thumbMap[thumb.size] = thumb;
  });
  return thumbMap;
};

export const getThumbnail = (imageArray, size) => {
  var sizedImage = imageArray.find(image => {
    return image.size === size;
  });

  if (sizedImage) {
    return sizedImage.url;
  } else {
    return imageArray[0].url;
  }
};

export const addImageParams = (url, params) => {
  return addParams(decodeURIComponent(url), { ...generalResizeParams, ...params });
}

export const buildOriginUrl = (uri, originTag = 'web-hf') => {
  if (!uri || uri.indexOf('origin=') > -1) {
    return uri;
  }
  if (originTag) {
    return addParams(uri, { origin: originTag });
  }
  return uri;
};

export const getThemeStyles = (theme) => {
  if (!theme) {
    return '';
  }

  const {
    color1,
    color2,
    textColor,
    darkModeColor1,
    darkModeColor2,
    darkModeTextColor,
  } = theme;

  /**
   * In case of the following scenarios, the value will be set to `initial` so that fallback colors
   * can default colors (periwinkle palette) will be used:
   * - The page does not have any theme data at all.
   * - The page as theme data, but its variable names do not FULLY match expected set (color1, color2,
   *   textColor).
   */
  return `
    :root {
      --themeColor1: ${color1 || 'initial'};
      --themeColor2: ${color2 || 'initial'};
      --themeTextColor: ${textColor || 'initial'};
      --themeDarkModeColor1: ${darkModeColor1 || 'initial'};
      --themeDarkModeColor2: ${darkModeColor2 || 'initial'};
      --themeDarkModeTextColor: ${darkModeTextColor || 'initial'};
    }`
}

export const normalizeFeedData = (data, type, originTag = '') => {
  let normalizedItems = [];
  let typeInfo = {};

  switch (type) {
    case TASTY:
      typeInfo = {
        type: TASTY,
        title: 'Tasty',
        url: 'https://tasty.co',
        seeMoreTrackingName: 'tasty',
      };
      break;
    case SHOPPING:
      typeInfo = {
        type: SHOPPING,
        title: 'Shopping',
        url: `${bfUrl}/shopping`,
        seeMoreTrackingName: 'shopping',
      };
      break;
    case NEWS:
      typeInfo = {
        type: NEWS,
        title: 'News',
        url: 'https://buzzfeednews.com',
        seeMoreTrackingName: 'news',
      };
      break;
    case QUIZ:
      typeInfo = {
        type: QUIZ,
        title: 'Quizzes',
        url: `${bfUrl}/quizzes`,
        seeMoreTrackingName: 'quizzes',
      };
      break;
    case TRENDING:
      typeInfo = {
        type: TRENDING,
        title: 'Trending',
        url: `${bfUrl}/trending`,
        seeMoreTrackingName: 'trending',
      };
      break;
    case SPONSORED:
      typeInfo = {
        type: SPONSORED,
      };
      break;
    case MORE:
      typeInfo = {
        type: MORE,
      };
      break;
    case SPLASH:
      typeInfo = {
        type: SPLASH,
      };
      break;
    default:
      typeInfo = {};
  }

  if (data) {
    normalizedItems = data.map(item => {
      // fix type for video here
      let itemType = type;
      if (item.type === VIDEO) {
        itemType = VIDEO;
      }
      let itemImage = '';
      let itemImageAlt = '';

      switch (itemType) {
        case TASTY:
          itemImage = addImageParams(item?.thumbnail_url.replace(
            's3.amazonaws.com',
            'img.buzzfeed.com'
          ), { fill: '208:208;center,top' });
          itemImageAlt = item?.thumbnail_alt_text;
          break;
        case VIDEO:
          itemImage = addImageParams(remapThumbnailData(item.thumbnails[0].sizes).standard
            .url || item.videos.cover_image_url);

          itemImageAlt =
            item.thumbnails?.[0]?.sizes[0].alt_text || item.description;
          break;
        default:
          itemImage = item.image || getThumbnail(item.thumbnails?.[0]?.sizes, 'big');
          itemImage = addImageParams(decodeURIComponent(itemImage), {
            ops: '600_398'
          });
          itemImageAlt =
            item.thumbnails?.[0]?.sizes[0].alt_text || item.description;
      }

      // is the item create date less than 12 hours ago
      const showCreatedAt = isAfter(
        new Date(item.created_at),
        sub(Date.now(), {
          hours: 12,
        })
      );

      const formattedCreatedAt = showCreatedAt
        ? formatTimestamp(item.created_at)
        : undefined;

      // Make quotes and apostrophes "Curly"
      const formattedItemName = formatQuotes(item.name);

      let url;
      if (type === TASTY) {
        url = `https://tasty.co/recipe/${item.slug}`;
      } else {
        url = buildOriginUrl(item.source_uri || item.url, originTag);
      }
      /**
       * FIXME: We don't have author/credit data for video posts
       * does this require another API call to augment the data?
       */
      const itemData = {
        id: item.id,
        name: formattedItemName,
        badges: item.badges || [],
        categoryInfo: formatCategoryInfo(
          item.classification?.section,
          item.badges?.[0],
          item.type,
          item.authors?.[0]
        ),
        type: itemType,
        createdAt: formattedCreatedAt,
        author: { name: item.authors?.[0]?.name, url: item.authors?.[0]?.page },
        url,
        thumbnail: itemImage,
        thumbnailAlt: itemImageAlt,
        tasty: {
          isCommunityRecipe: item.credits?.some(
            credit => credit?.type === 'community'
          ),
          isUnder30Min:
            item.total_time_minutes <= 30 && item.total_time_minutes > 0,
          brandName: item.brand ? item.brand?.name : '',
        },
        videoDurationDisplay: item.videos
          ? formatVideoDuration(item.videos[0].duration)
          : undefined,
        isFlexpro: item.is_flexpro ? item.is_flexpro : false,
        dataSource: {
          name: item.data_source,
          algorithm: item.is_flexpro
            ? [item.flexpro_source_algorithm, item.data_source_algorithm || '']
            : [item.data_source_algorithm || ''],
          version: item.is_flexpro
            ? [item.flexpro_source_algorithm_version]
            : [''],
        },
      };
      return itemData;
    });
  }

  return {
    items: normalizedItems,
    typeInfo,
    sponsor: data?.[0]?.sponsor,
  };
};

export const normalizePostContent = (content, { originTag, zoneName = '', index } = {}) => {
  const post = content?.post || {};
  const postOverrides = content?.post_overrides || {};
  const objectType = 'post_promo';
  let trackingData = {};
  let flexproVariation = {};
  /**
   * Special case of the first item in homepage trending splash (zone name: 'splash_trending')
   * The title used should be in this order:
   *  Post override title, if it exists (and skip flexpro)
   *  BPage promotion title (seo title), if it exists (and skip flexpro)
   *  otherwise do default logic
   */
  if (zoneName === 'splash_trending'
    && index === 0
    && (post?.socialpromotions?.bpage?.[0]?.title || postOverrides?.title)) {
    postOverrides.title = postOverrides?.title || post?.socialpromotions?.bpage?.[0]?.title;
  } else {
    flexproVariation = pickFlexproVariation(post);
    trackingData = flexproVariation?.is_flexpro ? {
      data_source_algorithm: [flexproVariation.flexpro_source_algorithm],
      data_source_algorithm_version: [flexproVariation.flexpro_source_algorithm_version],
    } : {};
  }

  /**
   * If there are no flexpro experiments active, then allow post overrides if they exist. Otherwise,
   * a flexpro variation will be chosen as post data and tracking will be updated accordingly..
   */
  const postData = merge({}, post, flexproVariation?.is_flexpro ? flexproVariation : postOverrides);

  /**
   * enrichment data
   * @todo
   * Error handling. Consider sending error event (datadog? sentry?) if a required enrichment is not valid from the
   * API response.
   */
  const comments = content?.comments || {};
  const contentReactions = content?.content_reactions || {};
  const pageviews = content?.pageviews || {};
  const resizeParamsStandard = zoneName !== 'trending_top' ? generalResizeParams : topicSplashResizeParams;

  /**
   * Handle thumbnail image data
   */
  const dblbigImage = postData.images?.dblbig;
  const squareImage = postData.images?.square || dblbigImage;
  const dblbigAlt = postData.images?.dblbig_alt_text || '';
  const squareAlt = postData.images?.square_alt_text || dblbigAlt;
  const thumbnail = {
    standard: {
      alt: dblbigAlt,
      url: dblbigImage ? addImageParams(dblbigImage, resizeParamsStandard) : '',
    },
    square: {
      alt: squareAlt,
      url: squareImage ? addImageParams(squareImage, generalResizeParamsSquare) : '',
    },
  };

  const categoryInfo = formatCategoryInfo(
    postData.classification?.section,
    postData.badges?.[0],
    undefined,
    postData.authors?.[0],
  );

  // is the item create date less than 12 hours ago
  const published = isAfter(
    new Date(postData.published * 1000),  // converts unix timestamp from seconds to milliseconds
    sub(Date.now(), {
      hours: 12,
    })
  );

  const formattedPublishedAt = published
    ? formatTimestamp(postData.published * 1000)
    : null;

  // Make quotes and apostrophes "Curly"
  const formattedItemTitle = formatQuotes(postData.title);

  if (comments.count) {
    comments.countFormatted = formatNumberToShorthand(comments.count);
  }

  if (pageviews.count) {
    pageviews.countFormatted = formatNumberToShorthand(pageviews.count);
  }

  return {
    category: categoryInfo,
    comments,
    contentReactions,
    id: postData.id,
    isPopular: false,
    isSponsored: false,
    pageviews,
    objectType,
    thumbnail,
    timestamp: formattedPublishedAt,
    title: formattedItemTitle,
    trackingData,
    url: buildOriginUrl(postData.canonical_url, originTag),
  };
};

export const normalizeArticleCarouselContent = (content = {}, { originTag = '' } = {}) => {
  if (!content?.posts?.length) {
    return {
      // To protect in case `content` is not an object
      ...(typeof content === 'object' ? content : {}),
      posts: [],
    };
  }

  const posts = content.posts.map((postData) => {
    const thumbnail = {
      standard: {
        url: addImageParams(postData.images.dblbig, generalResizeParams),
        alt: postData.images.dblbig_alt_text,
      },
      square: {
        url: addImageParams(
          (postData.images?.square || postData.images.dblbig),
          generalResizeParamsSquare
        ),
        alt: postData.images?.square_alt_text || postData.images.dblbig_alt_text,
      },
    };

    // Make quotes and apostrophes "Curly"
    const formattedItemTitle = formatQuotes(postData.title);

    return {
      id: postData.id,
      thumbnail,
      title: formattedItemTitle,
      url: buildOriginUrl(postData.canonical_url, originTag),
      object_type: 'article',
    };
  });

  return {
    ...content,
    posts,
  };
};

/**
 * @todo
 * Error handling. Consider sending error event (datadog? sentry?) if a required enrichment is not
 * valid from the API response.
 */
export const normalizeDiscussionContent = (content) => {
  if (!content || typeof content !== 'object') {
    return { comments: {} };
  }

  const { comments = {} } = content;
  if (comments?.count) {
    comments.countFormatted = formatNumberToShorthand(comments.count);
  }

  return {
    ...content,
    comments: comments || {},
  };
};

const normalizeTopCommentContent = (content, options) => {
  return {
    items: [{
      linkTitle: 'Mattel Released Even More Barbie Dolls Inspired By The Movie, So Here Are The Side-By-Sides',
      linkUrl: 'https://www.buzzfeed.com/laurengarafano/mattel-released-even-more-barbie-dolls-inspired-by-the-movie',
      image: 'https://img.buzzfeed.com/buzzfeed-stage/static/2023-09/20/21/campaign_images/32d83f983a5f/17-teeny-tiny-almost-unnoticeable-details-from-fi-3-6357-1695243785-1_big.jpg?fill=266%3A176%3Bcenter%2Ctop&output-format=auto&output-quality=auto&ops=600_398',
      text: 'I’m all for Ken’s story being told….Ryan Gosling just isn’t the right person I think Jacob Elordi would have been better',
      userName: 'user123456',
      userLink: 'https://stage.buzzfeed.com/sam_cleal',
      userImg: 'https://img.buzzfeed.com/buzzfeed-static/static/user_images/CKf3LZgvu_large.jpg?output-format=jpg&crop=480%3A480%3B19%2C15?output-format=jpeg&output-quality=85&downsize=30:*'
    }]
  };
};

const normalizeInOutContent = (content, options) => {
  return {
    items:[
      {
        title: 'What’s IN This Week',
        linkTitle: 'Mattel Released Even More Barbie Dolls Inspired By The Movie, So Here Are The Side-By-Sides',
        linkUrl: 'https://www.buzzfeed.com/laurengarafano/mattel-released-even-more-barbie-dolls-inspired-by-the-movie',
        image: 'https://img.buzzfeed.com/buzzfeed-stage/static/2023-09/20/21/campaign_images/32d83f983a5f/17-teeny-tiny-almost-unnoticeable-details-from-fi-3-6357-1695243785-1_big.jpg?fill=266%3A176%3Bcenter%2Ctop&output-format=auto&output-quality=auto&ops=600_398',
        list: [
          'Girl Dinner',
          'TikTok',
          'food'
        ],
      },
      {
        title: 'What’s IN This Week',
        linkTitle: 'Mattel Released Even More Barbie Dolls Inspired By The Movie, So Here Are The Side-By-Sides',
        linkUrl: 'https://www.buzzfeed.com/laurengarafano/mattel-released-even-more-barbie-dolls-inspired-by-the-movie',
        image: 'https://img.buzzfeed.com/buzzfeed-stage/static/2022-02/17/19/campaign_images/af9c2a9adebe/19-cleaning-hacks-for-people-who-are-super-lazy-b-2-2032-1645124424-6_big.jpg?fill=266%3A176%3Bcenter%2Ctop&output-format=auto&output-quality=auto&ops=600_398',
        list: [
          'Girl Dinner',
        ],
      }
    ],
  };
};

/**
 * @todo
 * Add "automatic" type of promo bar
 */
const normalizePromoBar = (content) => {
  return {
    ... content?.manual,
    type: content?.type,
  };
}

export const normalizeContentObjectMap = {
  article_carousel: normalizeArticleCarouselContent,
  discussion_question: normalizeDiscussionContent,
  in_out: normalizeInOutContent,
  post_promo: normalizePostContent,
  promo_bar: normalizePromoBar,
  top_comment: normalizeTopCommentContent
};

export const normalizeContentObject = ({ content, object_type, ...options }) => {
  let normalizedContent = {};

  if (content && typeof content === 'object') {
    normalizedContent = { ...content };

    if (normalizedContent?.images?.standard) {
      normalizedContent.images.standard = imgServiceParams(normalizedContent.images.standard);
    }
    if (normalizedContent?.images?.mobile) {
      normalizedContent.images.mobile = imgServiceParams(normalizedContent.images.mobile);
    }

    if (typeof normalizeContentObjectMap[object_type] === 'function') {
      return normalizeContentObjectMap[object_type](normalizedContent, options);
    }
  }

  return normalizedContent;
};

export const normalizeZone = (zone = {}, { originTag = '', debug = '' } = {}) => {
  const { items = [], metadata = {}, name = '', next = null } = zone;
  const normalizedItems = items.map(({ id, content, object_type }, index) => ({
    content: normalizeContentObject({ content, debug, object_type, originTag, zoneName: name, index }),
    id, // objectID
    object_type,
  }));

  return {
    items: normalizedItems,
    metadata,
    name,
    next,
  };
};

export const normalizeZones = (zones = [], { originTag = '', debug = '' } = {}) => {
  return zones.reduce((accZones, zone) => {
    if (zone.name) {
      accZones[zone.name] = normalizeZone(zone, { originTag, debug });
    }
    return accZones;
  }, {});
};

export const filterZonesByPrefix = (zones, prefix = '') =>
  Object.keys(zones).reduce((filteredZones, name) => {
    if (name.startsWith(prefix)) {
      filteredZones[name] = zones[name];
    }
    return filteredZones;
  }, {});

export const getFactOfTheDay = (items) => {
  const date = new Date();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  if (
    !items?.length ||
    !items[0].content?.facts
  ) return null;

  return items[0].content.facts[month][day];
};

/**
 * The sum of all replies from community questions active on a page at the time of the request.
 * @param {array} zones - The zones from the feed API that make up the page.
 * @returns {number} The total discussion count.
 */
export const getDiscussionCount = async (zones = []) => {
  let totalCount = 0;

  if (!zones || !zones.length) {
    return totalCount;
  }

  const communityObjectTypes = ['discussion_question'];

  for (const { items = [] } of zones) {
    for (const { content = {}, object_type = '' } of items) {
      const commentsCount = content.comments?.count;

      // These types should be enriched with comments API data
      if (communityObjectTypes.includes(object_type) && commentsCount) {
        totalCount += commentsCount ?? 0
      }
    };
  };

  return totalCount;
};
