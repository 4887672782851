import { useEffect } from 'react';
import { useIntersection } from '@buzzfeed/react-components';
import { useTrackingContext } from './useTrackingContext';

export const useViewImpression = ({
  trackingData = {},
  options = {},
  condition = true, // optional impression tracking
}) => {
  const { trackImpression } = useTrackingContext();
  const { isIntersecting, setObservable } = useIntersection({
    threshold: 0.5,
    once: true,
    ...options,
  });

  useEffect(() => {
    if (isIntersecting && condition) {
      trackImpression(trackingData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIntersecting]);

  return { isIntersecting, setObservable };
};
