import { CLUSTER } from './index';

export const getMockExperimentValues = ({ isMPHomepage }) => {
  return {
    key: 'WEB-4172-MP-homepage-rollout',
    values: {
      id: CLUSTER === 'prod' ? 1322 : 1145,
      version: 1,
      resolved: false,
      is_feature_flag: false,
      payload: null,
      value: isMPHomepage ? 'on' : 'control',
      variant_id: isMPHomepage ? 2 : 1
    }
  }
}
