import { PermutiveTracker } from '@buzzfeed/third-party-analytics';
import { CLUSTER, permutive_creds } from '../../../constants';

let trackingInstance;

const permutiveCredentials = edition => {
  const { api_key, project_id } =
    permutive_creds[edition] || permutive_creds['default'];

  return {
    projectId: project_id,
    apiKey: api_key,
  };
};

const permutive = (edition, section) => {
  const credentials = permutiveCredentials(edition);
  if (!trackingInstance) {
    trackingInstance = new PermutiveTracker({
      cluster: CLUSTER,
      ...credentials,
    });
  }
  return trackingInstance;
};

export async function trackPermutivePageView({ edition, section }) {
  const tracker = permutive(edition, section);
  const eventData = {
    page: {
      meta: {
        type: section,
        publisher: 'buzzfeed',
        platform: 'web',
        edition: edition,
      }
    },
  };
  tracker.trackPageView(eventData);
}