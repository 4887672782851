import { FEATURE_FLAG as CONSENT_FEATURE_FLAG } from '@buzzfeed/consent';

const featureFlags = [
  /*
  // Example usage
  {
    name: 'newsletter-popup',
    variations: ['control', 'on'],
    isEligible: () => true,
  },*/
  {
    name: 'TimeSpentRO_1',
    variations: ['on', 'control'],
    isEligible: () => true
  },
  {
    name: 'shoppybot-clippy-homepage',
    variations: ['control', 'on'],
    isEligible: () => true,
  },
  {
    name: 'homepage_grammys_promo',
    variations: ['control', 'on'],
    isEligible: () => true,
  },
  {
    name: 'RT-1042-Admiral-script-on-BFDC',
    variations: ['control', 'on'],
    isEligible: () => true,
  }
];

const serviceAbTests = [];

export const abTests = [
  CONSENT_FEATURE_FLAG,
  ...featureFlags,
  ...serviceAbTests,
];

export default abTests;
