import { useCallback, useEffect, useState, useContext } from 'react';
import { StickyContext } from '../../../contexts';
import AdUnit from '../AdUnit';
import styles from './adStickySidebar.module.scss';

const OFFSET = 75;

export const StickyAd = ({ adPos, sidebarEl, className, topPadding }) => {
  const [topAdjustment, setTopAdjustment] = useState(0);
  const stickyRegistry = useContext(StickyContext);

  const stickyHandler = useCallback(() => {
    if (sidebarEl.current) {
      const top = stickyRegistry.getAvailableTop(sidebarEl.current);
      const modTop = OFFSET;

      if (topPadding) {
        setTopAdjustment(top + topPadding);
      } else if (top <= modTop) {
        setTopAdjustment(modTop);
      } else {
        setTopAdjustment(top - 50);
      }
    }
  }, [sidebarEl, stickyRegistry]);

  useEffect(() => {
    stickyHandler();
    stickyRegistry.subscribe(stickyHandler);
    return () => stickyRegistry.unsubscribe(stickyHandler);
  }, [stickyHandler, stickyRegistry]);

  return (
    <div
      className={`${styles.container} ${className ? className : ''}`}
      style={{ top: `${topAdjustment}px` }}
    >
      <AdUnit type={adPos} className="Ad--sidebar" />
    </div>
  );
};

export default StickyAd;
