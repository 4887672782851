import { useEffect, useContext } from 'react';
import { useTrackingContext } from './useTrackingContext';
import { TrackingContext } from '../../contexts';
import { trackFacebookPageView } from '../analytics/facebook';
import { trackPermutivePageView } from '../analytics/permutive';

export function usePageviewTracking(tracking) {
  const {
    page_edition
  } = useContext(TrackingContext);
  const { trackPageview } = useTrackingContext();

  useEffect(() => {
    // run hook once
    trackPageview(tracking);
    trackFacebookPageView({});
    trackPermutivePageView({
      section: 'homepage',
      edition: page_edition,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ page_edition ]);
}
