import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { cookies } from '@buzzfeed/bf-utils';
import AdsProvider from '@buzzfeed/adlib/dist/module/bindings/react/components/AdsContext';
import { getMockExperimentValues } from '../../constants/mp-ab-test';
import { getPageContext } from '../../utils/ads/context';
import useABeagleAsync from '../../hooks/useABeagleAsync';
// TODO: FInd a way to remove this dependency?
import useLegacyTracking from '../../hooks/tracking/useLegacyTracking';
import { framework, rules } from '@buzzfeed/consent';

/* eslint-disable camelcase */
async function getPageTargeting({ abeagle, isMPHomepage, pageName, pagePath }) {
  const pageTargeting = pageName !== 'topic' ? {
    pagetype: 'A',
    destination: ['newhp', 'buzzfeedhomepage', 'buzzfeed'],
  } : {
    destination: ['topicpage'],
    topic: pagePath,
  };

  const hasTrackingConsent = await (!framework.needsConsent()
    ? Promise.resolve(true)
    : rules.hasConsented('tracking'));

  // Add experiments to abtest targeting
  /*
  const experiments = [];
  const experimentVariants = await Promise.all(
    experiments.map(experiment =>
      abeagle.getExperimentVariant(experiment, {
        rejectErrors: false,
        defaultVariantIfUnbucketed: null,
      })
    )
  );
  pageTargeting.abtest = experiments.map(
    (experiment, idx) => `${experiment}|${experimentVariants[idx]}`
  );*/

  /**
   * WEB-4172:
   * For homepage views, add experiment value - always set to "on"
   *  for mission possible, and "control" for non-mission possible
   *  This code can be removed when page is fully rolled out
   */
  if (pageName === 'home') {
    const mockExperiment = getMockExperimentValues({ isMPHomepage });
    pageTargeting.abtest = `${mockExperiment.key}|${mockExperiment.values.value}`;
  }

  return pageTargeting;
}
/* eslint-enable camelcase */

export default function Ads({
  edition = 'en-us',
  isMPHomepage,
  pagePath = 'hp',
  pageName,
  children,
}) {
  const abeagle = useABeagleAsync();
  const legacyTracking = useLegacyTracking();

  const getPageContextBound = useCallback(
    () =>
      getPageContext({ edition, abeagle, tracking: legacyTracking, pageName }),
    [edition, abeagle, legacyTracking, pageName]
  );
  const getPageTargetingBound = useCallback(
    () => getPageTargeting({ abeagle, pageName, pagePath, isMPHomepage }),
    [abeagle]
  );

  return (
    <AdsProvider
      pageId={pagePath}
      pageLanguage={edition}
      adsEnabled={true}
      getPageContext={getPageContextBound}
      getPageTargeting={getPageTargetingBound}
    >
      {children}
    </AdsProvider>
  );
}

Ads.propTypes = {
  edition: PropTypes.string,
  pagePath: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
